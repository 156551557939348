.ContactUsmodal {
    & > div {
      background-color: rgb(0, 0, 0, 0.9) !important;
      & > div {
        padding: 25px 15px !important;
        overflow: hidden !important;
      }
    }
  }
  .carouselModalScrollLayer {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(32, 31, 31, 0.92) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .carouselModalContainer {
    display: flex !important;
    justify-content: center !important;
    /* align-items: center !important; */
    width: 100% !important;
    height: max-content !important;
    & > button {
      & > span {
        color: var(--colorWhite);
      }
    }
    & > div {
      width: 90%;
      /* height: 400px; */
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .parentContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .imageContainer {
    position: relative;
    /* width: 350px; */
    width: 950px;
    height: 615px;
    @media (max-width: 767px) {
      height: 415px;
    }
    /* margin-right:10px; */
  
    /* & > img {
      max-width: 100%;
      border-radius: 10px;
      background-size: cover;
    } */
  }
  .sliderImageWrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
  }
  .sliderImageWrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
  }
  .muteButton{
    position: absolute;
    right: 30px;
    bottom: 60px;
    color: white;
    cursor: pointer;
    & > svg {
      width: 25px;
      height: 25px;
      /* & > path{
        fill:#fff;
        stroke:#fff;
      } */
      @media (max-width: 767px) {
        width: 22px;
        height: 22px;
      }
    }
    @media (max-width: 767px) {
      bottom: 8px;
      right: 8px;
    }
  }