.customNavbar {
  display: flex;
  background: none;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  right: 0;
  transform: translateY(58%);
  transition: background-color 0.3s ease;

  & .searchDiv {
    display: flex;
    align-items: center;
    width: 40%;
    & > svg {
      & > path {
        fill: #fff;
      }
    }

    & > input {
      border: none;
      background: none;
      font-size: 14px;
      font-family: 'Factor A';
      font-weight: 500;
      color: #fff;
      width: 250px;
      &:focus {
        box-shadow: none;
        &::placeholder {
          color: transparent !important;
        }
      }
      &::placeholder {
        color: #fff;
        &:focus {
          color: transparent !important;
        }
      }
    }
    & > input:-webkit-autofill,
    & > input:-webkit-autofill:hover,
    & > input:-webkit-autofill:focus,
    & > input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
      transition: background-color 5000s ease-in-out 0s;
      /* box-shadow: inset 0 0 20px 20px #23232329; */
    }
  }
  & .logoDiv {
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    @media (min-width: 1128px) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    /* margin-left: 100px; */
    & > a {
      display: inline-block;
      & > svg {
        width: 100px;
        height: auto;
      }
    }
    & .whiteLogo {
      & > path {
        fill: #fff !important;
        stroke: #fff !important;
      }
    }
  }
  & .listingLinkText {
    font-size: 14px;
    color: #fff;
    font-family: 'Factor A';
    font-weight: 500;
  }
  & .listingLinkText {
    padding: 0 50px;
  }
  & .joinSignIn {
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    padding: 8px 15px;
    border-radius: 40px;
    min-width: 116px;
    font-family: 'Factor A';
    /* @media(max-width:1023px){
      display:none;
    } */
  }
  .signLogIn {
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    padding: 8px 15px;
    border-radius: 40px;
    /* min-width: 116px; */
    width: 100px !important;
    font-family: 'Factor A';
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      color: #000;
      text-decoration: none !important;
    }
  }
}
.menuDiv {
  position: absolute;
  background-color: #fcfcfc;
  color: #6f6f6f;
  width: 198px;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-radius: 10px;
  right: 4px;
  margin-top: 14px;
  border: 0.2px solid #bdbdbd;
  box-shadow: 0 2px 10px 0px #0000000d;
  gap: 5px;
  cursor: pointer;
  top: 38px;
  & .linkText {
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
    &:hover {
      text-decoration: none;
    }
  }
  /* & .linkText:nth-child(1) {
    margin-bottom: 8px;
  } */
}

.signLogIn {
  background-color: #000;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 8px 15px;
  border-radius: 40px;
  /* min-width: 116px; */
  width: 100px !important;
  font-family: 'Factor A';
  display: flex;
  justify-content: center;
  align-items: center;
  & > a {
    color: #fff;
    text-decoration: none !important;
  }
}

.signLogInDiv {
  display: flex;
  gap: 10px;
}

.logoutLinkText {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 400;
  color: #ff0000;
  &:hover {
    text-decoration: none;
  }
}
.customNavbarscrolled {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 9px 0px;
  & .searchDiv {
    display: flex;
    align-items: center;
    & > svg {
      & > path {
        fill: #000;
      }
    }

    & > input {
      border: none;
      background: none;
      font-size: 14px;
      font-family: 'Factor A';
      font-weight: 500;
      color: #000;
      width: 250px;
      &:focus {
        box-shadow: none;
        &::placeholder {
          color: transparent !important;
        }
      }
      &::placeholder {
        color: #000;
      }
    }
    & > input:-webkit-autofill,
    & > input:-webkit-autofill:hover,
    & > input:-webkit-autofill:focus,
    & > input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #000;
      transition: background-color 5000s ease-in-out 0s;
      /* box-shadow: inset 0 0 20px 20px #23232329; */
    }
  }
  & .logoDiv {
    & > a {
      & > img {
        width: 100px;
        height: auto;
      }
    }
  }
  & .listingLinkText {
    color: #000;
  }
  & .listingLinkText {
    padding: 0 50px;
  }
  & .joinSignIn {
    color: #fff;
    background-color: #000;
  }
  & .signLogIn {
    background-color: #000;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    padding: 8px 15px;
    border-radius: 40px;
    /* min-width: 116px; */
    width: 100px !important;
    font-family: 'Factor A';
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      color: #fff;
      text-decoration: none !important;
    }
  }
}
.joinDiv {
}
.topbarMddle {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profileMenuContainer {
  position: relative;
  right: 39px;

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    border: 5px solid transparent;
    border-top-color: #000;
    position: absolute;
    margin-top: 4px;
    margin-left: 3px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
  }
}
.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}
.avatar {
  margin: 16px 0;
}
.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}
.customNavbarMain {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 9px 0px;
  padding: 20px 35px;
  z-index: 1;
  /* transform: translateY(0); */
  /* transform: translateY(50%); */
  transform: none;
  top: 50px;
  position: fixed;
  width: 100%;
  & .searchDiv {
    width: 40%;
    @media (max-width: 1199px) {
      width: 30%;
    }
  }
  & > div {
    position: relative;
    & > div {
      display: flex;
      align-items: center;
      & > svg {
        & > path {
          fill: #000 !important;
        }
      }
      & > input {
        background: none;
        border: none;
        color: #000;
        font-family: var(--fontFamilyFactor);
        font-size: 14px;
        font-weight: 500;
        /* width:250px; */
        &:focus {
          &::placeholder {
            color: transparent !important;
          }
        }
        &::placeholder {
          color: #000 !important;
        }
      }
      & > input:-webkit-autofill,
      & > input:-webkit-autofill:hover,
      & > input:-webkit-autofill:focus,
      & > input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #000;
        transition: background-color 5000s ease-in-out 0s;
        /* box-shadow: inset 0 0 20px 20px #23232329; */
      }
    }

    & > div:nth-child(3) {
      & > a {
        color: #000 !important;
        padding: 0 50px;
        font-family: var(--fontFamilyFactor);
        font-size: 14px;
        font-weight: 500;
      }
      & > button {
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 40px;
        /* color: #000; */
        cursor: pointer;
        font-family: var(--fontFamilyFactor);
        font-size: 14px;
        font-weight: 500;
        min-width: 116px;
        padding: 8px 15px;
        position: relative;
      }
    }
  }
}
.searchIcon {
  border: none;
}
.animatedList div {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.animatedList div.active {
  opacity: 1;
}
.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  /* position: absolute;
  top: 5px;
  right: 5px; */

  /* Style: red dot with white border */
  margin-bottom: 2px;
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  /* opacity: 0;
  transform: scale(0); */

  @media (--viewportMedium) {
    /* top: 23px; */
  }
}
.notificationParentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.inboxLink {
  display: flex;
  align-items: center;
  gap: 2px;
}
.customLogoDiv {
  @media (min-width: 1128px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  /* margin-left: 100px; */
  & > a {
    display: inline-block;
    & > svg {
      width: 100px;
      height: auto;
    }
  }
}
