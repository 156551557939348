.imgDescDiv {
    width: 100%;
    /* display: flex; */
    gap: 20px;
    margin-top: 55px;
    position: relative;
  
    @media (max-width: 991px) {
      margin-top: 100px;
    }
    @media (max-width: 767px) {
      margin-top: 60px;
    }
    /* background-color: green; */
    & > div {
      & > svg {
        top: unset !important;
        bottom: -70px !important;
        z-index: 1;
        padding: 15px 0;
        @media(max-width:767px){
          display: block !important;
        }
      }
    }
  }
  
  .imgDescDivTransform {
    & > div {
      & > div {
        /* display: flex;
        justify-content: flex-start; */
        & > div {
          /* margin-left: 0 !important; */
          /* background-color: #000000; */
          transform: none !important;
        }
      }
    }
  }
  .sliderContainer{
    position:relative;
  }
  .imageContainer {
    position: relative;
    /* width: 350px; */
    height: 415px;
    /* margin-right:10px; */
  
    /* & > img {
      max-width: 100%;
      border-radius: 10px;
      background-size: cover;
    } */
  }
  .previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .muteButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
    & > svg {
      width: 25px;
      height: 25px;
      /* & > path{
        fill:#fff;
        stroke:#fff;
      } */
      @media (max-width: 767px) {
        width: 22px;
        height: 22px;
      }
    }
    @media (max-width: 767px) {
      bottom: 8px;
      right: 8px;
    }
  }
  .motivationalQuote {
    padding: 30px 20px;
    /* width: 50%; */
    height: 415px;
    border-radius: 10px;
    background-color: #00a661;
    /* margin-right: 10px; */
    @media (max-width: 575px) {
      & > p {
        font-size: 12px;
      }
      & > div {
        font-size: 16px;
      }
    }
    & > p {
      font-family: 'Factor A';
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      @media (max-width: 575px) {
        font-size: 17px;
        margin-bottom: 0px !important;
      }
    }
    & > div {
      font-family: 'Factor A';
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      max-height: 200px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      @media (max-width: 575px) {
        font-size: 14px;
        line-height: 26px;
        padding-top:10px;
      }
    }
  }
  .evenColor{
    background: #FDB8CF;
  }